import React from "react"
import Image from "gatsby-image"
import styled from "styled-components"
import { Carousel } from "react-bootstrap"

const CarouselItem = styled(Carousel.Item)`
  height: calc(100vh - 80px);
  .image {
    width: 100%;
    height: 100%;
  }
`

function Hero({ sliderData }) {
  const carouselItems = sliderData.map(element => {
    const {
      id,
      naglowek: title,
      // opis: description,
      zdjecie: { fluid: image },
    } = element
    return (
      <CarouselItem key={id}>
        <Image fluid={image} alt="Slide" className="image" />
        <Carousel.Caption>
          <h2>{title}</h2>
          {/* <p>{description}</p> */}
        </Carousel.Caption>
      </CarouselItem>
    )
  })

  return (
    <Carousel>
      {carouselItems}

      {/* <Carousel.Item>
        <img className="d-block w-100" src={slide1} alt="First slide" />
        <Carousel.Caption>
          <h3>Fizjoterapeuta Wojciech Sułkowski</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={slide2} alt="Second slide" />

        <Carousel.Caption>
          <h3>Fizjoterapeuta Wojciech Sułkowski</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={slide3} alt="Third slide" />

        <Carousel.Caption>
          <h3>Fizjoterapeuta Wojciech Sułkowski</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption>
      </Carousel.Item> */}
    </Carousel>
  )
}

export default Hero
