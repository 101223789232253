import React from "react"

import Hero from "./Hero"
import Kosmo from "./Kosmo"

function Home({ data }) {
  const {
    allContentfulSliderElement: { nodes: sliderData },
    allContentfulKosmoestetic: { nodes: kosmoData },
  } = data

  return (
    <>
      <Hero sliderData={sliderData} />
      <Kosmo kosmoData={kosmoData} />
    </>
  )
}

export default Home
